@charset "utf-8";
@import "setting";
@import "components/reset";
@import "addon/swiper";
@import "addon/magnific-popup";


/*----------------------
  common
----------------------*/

a {
  color: $mainC;
  /* text-decoration:none; */
  &:hover {
    color: $mainC;
    text-decoration: none;
  }
}

.list-mark {
  text-indent: -1em;
  padding: 0 0 0 1em;
  overflow: hidden;
}


form p {
  margin-bottom: 30px;
  border-bottom: solid #eee 1px;
  padding-bottom: 10px;
}
form p select {
  display: inline;
}
form p textarea {
  vertical-align: top;
}
.attention {
  color: #f00;
}

form .back{
  display: inline-block;
    padding: 15px 50px;
    box-sizing: border-box;
    background: #ccc;
    font-size: 16px;
}
form .confirm{
  display: inline-block;
  padding: 15px 50px;
  box-sizing: border-box;
  background: #333333;
  font-size: 16px;
  color: #fff;
  vertical-align: top;
  font-weight: bold;
}
/*----------------------
  common
----------------------*/

@media print, screen and (min-width: $w-sp + 1) {
  @import "pc";
}

/*end query*/
@media screen and (max-width: $w-sp) {
  @import "sp";
}
