@charset "utf-8";
//============================================================
//    基本設定
//============================================================
.mb-l {
	margin-bottom: 30px;
  }
  .mb-m {
	margin-bottom: 15px;
  }
  .mb-s {
	margin-bottom: 10px;
  }
  .header {
	padding: 10px;
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	height: auto;
	z-index: 10;
	background: #fff;
	justify-content: space-between;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	z-index: 15;
	opacity: 0;
	transition: opacity $easeInSine 300ms 1000ms;
	&.show {
	  opacity: 1;
	}
	.logo {
	  padding-bottom: 15px;
	  width: 160px;
	  a {
		img {
		  width: 100%;
		  height: auto;
		}
	  }
	}

	.gnav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
		padding: 15px;
		transition: transform $easeInSine 500ms;
		transform: translateY(-100vh);
		background: #fff;
	  ul {
		li {
		  a {
			display: block;
			padding: 10px 0;
			text-align: center;
			color: $normalC;
			&.contact {
			  background: $mainC;
			  color: #fff;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  .ico {
				width: 20px;
				margin-right: 10px;
				display: inline-block;
				img {
				  width: 100%;
				}
			  }
			}
		  }
		}
	  }
	  .tel-box {
		background: $colorB;
		padding: 10px 5px;
		color: #fff;
		text-align: center;
		.title {
		  font-size: 12px;
		  margin-bottom: 5px;
		}
		.tel {
			color: #fff;
		  .ico {
			width: 20px;
			margin-right: 10px;
			display: inline-block;
			img {
			  width: 100%;
			}
		  }
		}
	  }
	}
  }
  .menu-trigger {
	position: fixed;
	right: 10px;
	top: 10px;
	width: 60px;
	height: 60px;
	padding: 10px;
	box-sizing: border-box;
	z-index: 3;
	&.show {
	  opacity: 1;
	}
	span {
	  width: 40px;
	  height: 1px;
	  background: $normalC;
	  position: relative;
	  display: block;
	  transition: transform $easeInSine 500ms;

	  &:before {
		content: "";
		width: 100%;
		height: 1px;
		background: $normalC;
		position: absolute;
		top: 15px;
		transform: rotate(0);
		transition: transform $easeInSine 500ms;

	  }
	  &:after {
		content: "";
		width: 100%;
		height: 1px;
		background: $normalC;
		position: absolute;
		top: 30px;
		transform: rotate(0);
		transition: transform $easeInSine 500ms;

	  }
	}
  }
  .menu-show{
	  
	  .header{
		  .gnav{
			transform: translateY(0vh);
		  }
	  }
	  .menu-trigger{
		span{
			background: transparent;
			&:before{
			  transform: rotate(45deg);
				background: $normalC;

			}
			&:after{
			  transform: rotate(-45deg);
				background: $normalC;
				top:15px;
			}
		}
	}
  }
  body {
	background: $normalC;
	line-height: 1.8;
	background-color: #f2fdf5;
	color: $normalC;
	font-size: 14px;
	font-family: $fg;
	-webkit-font-smoothing: antialiased;
	padding: 15px;
	box-sizing: border-box;
	overflow-x: hidden;
	a {
	  text-decoration: none;
	  color: $mainC;
	  transition: $ease 300ms;
	  &:hover {
	  }
	}
  }
  .first-wrap {
	position: relative;
	// overflow: hidden;
	margin-bottom: 50px;
	height: 400px;
	width: 100%;
	box-sizing: border-box;
	padding-top: 80px;
	.slider-wrap {
	  position: relative;
	  opacity: 0;
	  transition: opacity $easeInSine 1000ms;
	  &.show {
		opacity: 1;
	  }
	  .swiper-container {
		height: 300px;
		.swiper-wrapper {
		  .swiper-slide {
			p {
			  width: 100%;
			  height: 300px;
			  background-repeat: no-repeat;
			  background-position: center center;
			  background-size: cover;
			}
		  }
		}
	  }
	  .swiper-pagination {
	  }
	}
	.catch-box {
	  position: absolute;
	  left: 0px;
	  top: 360px;
	  font-family: $fm;
	  z-index: 12;
	  height: auto;
	  width: 100%;
	  height: 130px;
	  color: $mainC;
	  h1 {
		font-size: 22px;
		width: 100%;
		margin-bottom: 30px;
		span {
		  background: #fff;
		  padding: 15px;
		  position: relative;
		  &:after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			left: 0;
			position: absolute;
			background: $mainC;
			transform: scaleX(1);
			transform-origin: left top;
			transition: transform $easeInSine 500ms 300ms;
		  }
		}
		&.show {
		  span {
			&:after {
			  transform: scaleX(0);
			}
		  }
		}
	  }
	  h2 {
		font-size: 12px;
		width: 100%;
		span {
		  background: #fff;
		  padding: 15px;
		  position: relative;
		  &:after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			left: 0;
			position: absolute;
			background: $mainC;
			transform: scaleX(1);
			transform-origin: right top;
			transition: transform $easeInSine 500ms 400ms;
		  }
		}
		&.show {
		  span {
			&:after {
			  transform: scaleX(0);
			}
		  }
		}
	  }
	}
  }
  .top-contents {
	background: url(../img/logo2.svg) no-repeat right 150px #f2fdf5;
	background-size: 300px;
	background-attachment: fixed;
	position: relative;
	box-sizing: border-box;
	.top-common {
	  padding: 30px 10px;
	  box-sizing: border-box;
  
	  .head-line {
		color: $normalC;
		margin-bottom: 30px;
		display: flex;
		align-items: flex-end;
		&.loaded {
		  h1 {
			span {
			  &:after {
				transform: scaleX(0);
			  }
			}
		  }
		  h2 {
			span {
			  background: $mainC;
			  &:after {
				transform: scaleX(0);
			  }
			}
		  }
		}
		h1 {
		  font-family: $fm;
		  font-size: 24px;
		  letter-spacing: 0.2em;
		  margin-bottom: 15px;
		  font-weight: normal;
		  line-height: 24px;
		  span {
			padding: 5px 30px 5px 0;
			border-bottom: 1px solid $mainC;
			display: inline-block;
			position: relative;
			&:after {
			  content: "";
			  width: 100%;
			  height: 100%;
			  display: block;
			  top: 0;
			  left: 0;
			  position: absolute;
			  background: $mainC;
			  transform: scaleX(1);
			  transform-origin: left top;
			  transition: transform $easeInSine 500ms 300ms;
			}
		  }
		}
		h2 {
		  font-family: $fg;
		  font-size: 12px;
  
		  span {
			color: #fff;
			background: transparent;
			padding: 5px 20px;
			display: inline-block;
			position: relative;
			transition: background-color $easeInSine 500ms 300ms;
  
			&:after {
			  content: "";
			  width: 100%;
			  height: 100%;
			  display: block;
			  top: 0;
			  left: 0;
			  position: absolute;
			  background: $colorB;
			  transform: scaleX(1);
			  transform-origin: left top;
			  transition: transform $easeInSine 500ms 300ms;
			}
		  }
		}
		&:after {
		  // content: "";
		  // width: 180px;
		  // height: 1px;
		  // background: $mainC;
		  // display: block;
		  // margin-top: 15px;
		}
	  }
	  &.concept-wrap {
		// background: $colorY;
		.concept-inner {
		  text-align: center;
		  font-family: $fm;
		  font-size: 16px;
		  .title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 15px;
		  }
		  
		}
	  }
	  .inner {
		margin: 0 auto;
		width: 98%;
		
		.sekoujirei-list {
		  display: flex;
		  flex-wrap: wrap;
		  justify-content: space-between;
		  opacity: 0;
		  transition: opacity $easeInSine 600ms 300ms;
		  &.loaded {
			opacity: 1;
		  }
		  li {
			width: calc(100% / 2);
  
			a {
			  display: block;
			  padding: 10px;
			  background: #fff;
			  transition: background-color $easeInSine 300ms;
			  &:hover {
				background: #f5f5f5;
			  }
				.photo {
				  margin-bottom: 10px;
				  text-align: center;
				  img {
					width: auto;
					height: 150px;
				  }
				}
				.detail {
					.date {
				  font-size: 12px;
				  margin-bottom: 5px;
				}
				.cat {
				  background: $mainC;
				  padding: 5px;
				  font-size: 14px;
				  margin-bottom: 10px;
				  color: #fff;
				  display: inline-block;
				}
				.title {
				  font-weight: bold;
				}
			  }
			}
		  }
		}
		.news-list {
		  li {
			border-bottom: 1px solid $mainC;
			a {
			  padding: 10px;

			  .date {
				background: $colorY;
				width: 80px;
				padding: 5px;
				color: #fff;
				font-size: 12px;
				text-align: center;
			  }
			  .cat{
				background: $colorG;
				width: 80px;
				padding: 5px;
				color: #fff;
				font-size: 12px;
				text-align: center;
			  }
			  .title {
				width: 100%;
				padding: 10px 0;
				color: $normalC;
			  }
			}
		  }
		}
		.voice-list {
		  li {
			display: flex;
			align-items: center;
			opacity: 0;
			transition: opacity $easeInSine 600ms 300ms;
			&.loaded {
			  opacity: 1;
			}
			.balloon-left {
			  position: relative;
			  display: inline-block;
			  margin: 1.5em 0 1.5em 15px;
			  padding: 15px;
			  min-width: 120px;
			  max-width: 100%;
			  background: #fff;
			  border: solid 3px $colorY;
			  border-radius: 4px;
			  box-sizing: border-box;
			  &:before {
				content: "";
				position: absolute;
				top: 50%;
				left: -24px;
				margin-top: -12px;
				border: 12px solid transparent;
				border-right: 12px solid #fff;
				z-index: 2;
			  }
  
			  &:after {
				content: "";
				position: absolute;
				top: 50%;
				left: -30px;
				margin-top: -14px;
				border: 14px solid transparent;
				border-right: 14px solid $colorY;
				z-index: 1;
			  }
			}
			.icon {
			  width: 80px;
			  height: 80px;
			  padding: 10px;
			  box-sizing: border-box;
			  background: #fff;
			  border-radius: 40px;
			  margin-right: 10px;
			  overflow: hidden;
			  img {
				width: 100%;
			  }
			}
			&.right {
			  flex-direction: row-reverse;
			  .balloon-right {
				position: relative;
				display: inline-block;
				margin: 1.5em 15px 1.5em 0;
				padding: 15px;
				min-width: 120px;
				max-width: 100%;
				background: #fff;
				border: solid 3px $colorY;
				box-sizing: border-box;
				border-radius: 4px;
  
				&:before {
				  content: "";
				  position: absolute;
				  top: 50%;
				  right: -24px;
				  margin-top: -12px;
				  border: 12px solid transparent;
				  border-left: 12px solid #fff;
				  z-index: 2;
				}
  
				&:after {
				  content: "";
				  position: absolute;
				  top: 50%;
				  right: -30px;
				  margin-top: -14px;
				  border: 14px solid transparent;
				  border-left: 14px solid $colorY;
				  z-index: 1;
				}
			  }
  
			  .icon {
				margin-right: 0;
				margin-left: 10px;
			  }
			}
		  }
		}
		.banner-list {
		 
		  opacity: 0;
		  transition: opacity $easeInSine 600ms 300ms;
		  &.loaded {
			opacity: 1;
		  }
		  li {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			a {
			  display: block;
			  padding: 10px;
			  position: relative;
			  box-sizing: border-box;
			  &:after {
				content: "";
				position: absolute;
				left: 10px;
				top: 10px;
				border: 1px solid #fff;
				width: calc(100% - 20px);
				height: calc(100% - 20px);
			  }
			  &.bg-y {
				background: $colorY;
			  }
			  &.bg-p {
				background: #e69c99;
			  }
			  &.bg-b {
				background: $colorB;
			  }
			  .icon {
				width: 100px;
				height: 100px;
				border-radius: 50px;
				background: #fff;
				overflow: hidden;
				padding: 20px;
				display: block;
				margin: 15px auto 15px;
				text-align: center;
				img {
				  width: 100%;
				}
			  }
			  .detail {
				width: 90%;
				margin: 0 auto 30px;
				.title {
				  color: #fff;
				  font-weight: bold;
				  font-size: 18px;
				  font-family: $fm;
				}
				.text {
				  color: #fff;
				}
			  }
			}
		  }
		}
	  }
	}
  }
  .contents {
	  padding-top:80px;
	.header-box {
	  background-position: center center;
	  background-repeat: no-repeat;
	  background-size: cover;
	  padding: 60px 0;
	  text-align: center;
	  font-family: $fm;
	  letter-spacing: 0.2em;
	  margin-bottom: 30px;
	  .title {
		font-size: 24px;
		font-weight: normal;
		margin-bottom: 15px;
		color: #fff;
		letter-spacing: 0.2em;
		text-shadow:0px 0px 5px rgba(0,0,0,0.8);
  
		span {
		  display: inline-block;
		  padding: 0px 10px;
		  // background: rgba(255, 255, 255, 0.8);
		  // border-bottom: 1px solid #fff;
		  &:after {
			content: "";
			display: block;
			width: calc(100% + 60px);
			margin-left: -30px;
			height: 1px;
			background: #fff;
			transform: scaleX(0);
  
			transition: transform $easeInSine 500ms 300ms;
		  }
		}
	  }
	  .sub-title {
		font-size: 12px;
		color: #fff;
		transition: opacity $easeInSine 500ms 300ms;
		opacity: 0;
		text-shadow:0px 0px 5px rgba(0,0,0,0.8);

	  }
	  &.loaded {
		.title {
		  span {
			&:after {
			  transform: scaleX(1);
			}
		  }
		}
		.sub-title {
		  opacity: 1;
		}
	  }
	}
	.head-line {
	  color: $normalC;
	  margin-bottom: 30px;
	  display: flex;
	  align-items: flex-end;
	  flex-wrap: wrap;
	  &.loaded {
		h1 {
		  span {
			&:after {
			  transform: scaleX(0);
			}
		  }
		}
		h2 {
		  span {
			background: $mainC;
			&:after {
			  transform: scaleX(0);
			}
		  }
		}
	  }
	  h1 {
		font-family: $fm;
		font-size: 24px;
		letter-spacing: 0.2em;
		margin-bottom: 15px;
		font-weight: normal;
		line-height: 24px;
		span {
		  padding: 5px 30px 5px 0;
		  border-bottom: 1px solid $mainC;
		  display: inline-block;
		  position: relative;
		  &:after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			left: 0;
			position: absolute;
			background: $mainC;
			transform: scaleX(1);
			transform-origin: left top;
			transition: transform $easeInSine 500ms 300ms;
		  }
		}
	  }
	  h2 {
		font-family: $fg;
		font-size: 12px;
  
		span {
		  color: #fff;
		  background: transparent;
		  padding: 5px 20px;
		  display: inline-block;
		  position: relative;
		  transition: background-color $easeInSine 500ms 300ms;
  
		  &:after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			left: 0;
			position: absolute;
			background: $colorB;
			transform: scaleX(1);
			transform-origin: left top;
			transition: transform $easeInSine 500ms 300ms;
		  }
		}
	  }
	  &:after {
		// content: "";
		// width: 180px;
		// height: 1px;
		// background: $mainC;
		// display: block;
		// margin-top: 15px;
	  }
	  
	  .tag{
		display: block;
		width: 100%;
		text-align: right;
		a{
		  display: inline-block;
		  margin-right: 10px;
		  &:before{
			content: '#';
		  }
		}
	  }
	}
	.sub-contents {
	  padding: 30px 10px;
	  &.no-title {
		padding: 0px 10px 20px;
	  }
	  &.bg-y {
		background: #fff6d8;
	  }
	  &.bg-f {
		background: #fff;
	  }
	  .inner {
		margin: 0 auto;
		width: 98%;
		.wb-list{
			padding: 10px;
			background: #fff;
			box-sizing: border-box;
				  dt{
			  padding-top: 15px;
			  .title{
				font-weight: bold;
				font-size: 22px;
				margin-bottom: 15px;
				color: $colorOR;
			  }
			}
				  dd{
			  padding-bottom: 15px;
			  margin-bottom: 15px;
					  .top-text{
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 15px;
			  }
			  .img{
				text-align: center;
				margin-bottom: 15px;
				img{
				  max-width: 100%;
				  height: auto;
				}
			  }
			.sub-text{
				font-size: 16px;
				margin-bottom: 15px;
			  }
			  border-bottom: 1px solid $mainC;
			}
		  }
		.concept-inner {
		  text-align: center;
		  font-family: $fm;
		  font-size: 18px;
		  .title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 15px;
		  }
		  &.philosophy{
			.title{
			  text-align: center;
			}
			text-align: left;
		  }
		}
		.style-list {
		  li {
			margin-bottom: 30px;
			opacity: 0;
			transition: opacity $easeInSine 500ms 300ms;
			&.loaded {
			  opacity: 1;
			}
			.detai {
			  display: flex;
			  .photo {
				width: 320px;
				position: relative;
				z-index: 1;
				img {
				  width: 100%;
				  height: auto;
				}
			  }
  
			  .detail-area {
				background: rgba(255, 255, 255, 0.6);
				padding: 30px 30px 30px 50px;
				width: calc(100% - 320px);
				transform: translate3d(-15px, 15px, 0);
  
				.title {
				  font-size: 24px;
				  color: $colorG;
				  font-weight: bold;
				  span {
					display: inline-block;
					border-bottom: 1px solid $colorG;
					margin-bottom: 10px;
				  }
				}
				.text {
				}
			  }
			  .btn {
			  }
			}
		  }
		}
		.category-wrap {
		  margin-bottom: 50px;
		  .category-list {
			text-align: center;
			li {
			  text-align: center;
			  padding: 15px;
			  display: inline-block;
			  a {
				display: inline-block;
				padding: 15px 30px;
				background: $colorB;
				color: #fff;
				&:hover {
				  background: $colorY;
				}
			  }
			}
		  }
		}
		.company-table {
		  width: 100%;
		  margin-bottom: 30px;
		  tr {
			border-bottom: 1px solid #eee;
			th {
			  padding: 10px;
			  text-align: center;
			  display: block;
			  background: #eee;
			}
			td {
			  padding: 10px; 
			  display: block;
			}
		  }
		}
		.map-wrap {
		  iframe {
			width: 100%;
		  }
		}
		.sekoujirei-list {
		  display: flex;
		  flex-wrap: wrap;
		  justify-content: space-between;
		  opacity: 0;
		  transition: opacity $easeInSine 600ms 300ms;
		  &.loaded {
			opacity: 1;
		  }
		  li {
			width: calc(100% / 2);
			&:nth-child(even) {
			  background: #f5f5f5;
			}
			a {
			  display: block;
			  padding: 10px;
			  background: #fff;
			  transition: background-color $easeInSine 300ms;
			  &:hover {
				background: #f5f5f5;
			  }
				.photo {
				  margin-bottom: 15px;
				  text-align: center;
				  img {
					width: auto;
					height: 150px;
				  }
				}
				.detail {

				.date {
				  font-size: 12px;
				  margin-bottom: 5px;
				}
				.cat {
				  background: $mainC;
				  padding: 5px;
				  font-size: 14px;
				  margin-bottom: 10px;
				  color: #fff;
				  display: inline-block;
				}
				.title {
				  font-weight: bold;
				}
			  }
			}
		  }
		}
		.news-list {
			li {
				border-bottom: 1px solid $mainC;
				a {
				  padding: 10px;
	
				  .date {
					background: $colorY;
					width: 80px;
					padding: 5px;
					color: #fff;
					font-size: 12px;
					text-align: center;
				  }
				  .cat{
					background: $colorG;
					width: 80px;
					padding: 5px;
					color: #fff;
					font-size: 12px;
					text-align: center;
				  }
				  .title {
					width: 100%;
					padding: 10px 0;
					color: $normalC;
				  }
				}
			}
		}
		.voice-list {
		  li {
			display: flex;
			align-items: center;
			opacity: 0;
			transition: opacity $easeInSine 600ms 300ms;
			&.loaded {
			  opacity: 1;
			}
			.balloon-left {
			  position: relative;
			  display: inline-block;
			  margin: 1.5em 0 1.5em 15px;
			  padding: 15px;
			  min-width: 120px;
			  max-width: 100%;
			  background: #fff;
			  border: solid 3px $colorY;
			  border-radius: 4px;
			  box-sizing: border-box;
			  &:before {
				content: "";
				position: absolute;
				top: 50%;
				left: -24px;
				margin-top: -12px;
				border: 12px solid transparent;
				border-right: 12px solid #fff;
				z-index: 2;
			  }
  
			  &:after {
				content: "";
				position: absolute;
				top: 50%;
				left: -30px;
				margin-top: -14px;
				border: 14px solid transparent;
				border-right: 14px solid $colorY;
				z-index: 1;
			  }
			}
			.icon {
			  width: 140px;
			  height: 140px;
			  padding: 30px;
			  box-sizing: border-box;
			  background: #fff;
			  border-radius: 70px;
			  margin-right: 20px;
			  overflow: hidden;
			  img {
				width: 100%;
			  }
			}
			&.right {
			  flex-direction: row-reverse;
			  .balloon-right {
				position: relative;
				display: inline-block;
				margin: 1.5em 15px 1.5em 0;
				padding: 15px;
				min-width: 120px;
				max-width: 100%;
				background: #fff;
				border: solid 3px $colorY;
				box-sizing: border-box;
				border-radius: 4px;
  
				&:before {
				  content: "";
				  position: absolute;
				  top: 50%;
				  right: -24px;
				  margin-top: -12px;
				  border: 12px solid transparent;
				  border-left: 12px solid #fff;
				  z-index: 2;
				}
  
				&:after {
				  content: "";
				  position: absolute;
				  top: 50%;
				  right: -30px;
				  margin-top: -14px;
				  border: 14px solid transparent;
				  border-left: 14px solid $colorY;
				  z-index: 1;
				}
			  }
  
			  .icon {
				margin-right: 0;
				margin-left: 20px;
			  }
			}
		  }
		}
		.banner-list {
		  
		  opacity: 0;
		  transition: opacity $easeInSine 600ms 300ms;
		  &.loaded {
			opacity: 1;
		  }
		  li {
			width: 100%;
			padding: 15px;
			box-sizing: border-box;
			a {
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  padding: 30px;
			  position: relative;
			  flex-wrap: wrap;
			  &:after {
				content: "";
				position: absolute;
				left: 15px;
				top: 15px;
				border: 1px solid #fff;
				width: calc(100% - 30px);
				height: calc(100% - 30px);
			  }
			  &.bg-y {
				background: $colorY;
			  }
			  &.bg-p {
				background: #e69c99;
			  }
			  &.bg-b {
				background: $colorB;
			  }
			  .icon {
				width: 100px;
				height: 100px;
				border-radius: 50px;
				background: #fff;
				margin-right: 15px;
				overflow: hidden;
				padding: 20px;
				img {
				  width: 100%;
				}
			  }
			  .detail {
				width: calc(100% - 115px);
				.title {
				  color: #fff;
				  font-weight: bold;
				  font-size: 18px;
				  font-family: $fm;
				}
				.text {
				  color: #fff;
				}
			  }
			}
		  }
		}
		.entry-detail {
		  .entry-header {
			// background: #fefefe;
			// padding: 30px;
			margin-bottom: 15px;
			position: relative;
			.title {
			  color: $colorG;
			  font-size: 24px;
			  border-bottom: 1px solid $colorG;
			  padding-bottom: 5px;
			  margin-bottom: 2px;
			}
			.date {
			  font-size: 12px;
			}
			.cat {
			  font-size: 12px;
			  background: $colorG;
			  color: #fff;
			  padding: 2px 5px;
			  text-align: center;
			  position: absolute;
			  right: 0px;
			  bottom: -1px;
			}
		  }
		  .entry {
			border: 1px solid $colorG;
			background: #fff;
			padding: 30px;
			p {
			}
			img {
			  max-width: 100%;
			  height: auto;
			}
		  }
		}
		.gallery-wrap{
			display: flex;
			flex-wrap: wrap;
			li{
			  width: 50%;
			  padding: 0 15px;
			  margin-bottom: 30px;
			  a{
				display: block;
				transition: all 300ms;
				opacity: 1;
				text-align: center;
				&:hover{
				  opacity: 0.8;
				}
				img{
				  width: auto;
					height: 150px;
				}
			  }
			}
		  }
		  .sekou-main-image{
			text-align: center;
			margin-bottom: 30px;
			img{
			  max-width: 100%;
			}
		  }
		  .comment{
			background: #fff;
			margin-bottom: 30px;
			padding: 5px;
		  }
		  .detail-table{
			table{
			  border-top: 1px solid $normalC;
			  width: 100%;
			  tr{
				border-bottom: 1px solid $normalC;
				th{
				  padding: 5px;
	
				}
				td{
				  padding: 5px;
				}
			  }
			}
			
		  }
	  }
	}
  }
  .footer {
	position: relative;
	padding: 30px 10px;
	background: #fff;
  
	.page-top {
	  display: none;
	  a {
		span {
		}
	  }
	}
	.footer-inner {
	  margin: 0 auto;
	  ul {
		li {
		  display: inline-block;
		  a {
			display: block;
			padding: 5px 15px 5px 0;
			&.contact {
			  .ico {
				  display: none;
				
			  }
			}
		  }
		}
	  }
	  .company-area {
		text-align: center;
		width: 100%;
		box-sizing: border-box;
		.title {
		  font-weight: bold;
		  margin-bottom: 15px;
		}
		.address {
		  font-style: normal;
		}
	  }
	}
	.copy {
	  padding-top: 30px;
	  text-align: center;
	  p {
	  }
	}
  }
  .sub-page {
	section {
	  .inner {
	  }
	}
  }
  
  .more {
	display: block;
	width: 280px;
	height: 60px;
	background: $mainC;
	color: #fff;
	font-size: 18px;
	line-height: 60px;
	text-align: center;
	margin: 30px auto;
	&:hover {
	  background: $colorB;
	  color: #fff;
	}
	&.bg-f {
	  color: $mainC;
	  background: #fff;
	}
  }

form p em{
	display:block;
  }
  form input[type="text"],
  form input[type="email"] {
	max-width: 90%;
  }
  form textarea {
	max-width: 90%;
  }
  