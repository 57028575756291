@charset "utf-8";
//============================================================
//    基本設定
//============================================================
.mb-l {
  margin-bottom: 50px;
}
.mb-m {
  margin-bottom: 30px;
}
.mb-s {
  margin-bottom: 15px;
}
.header {
  padding: 30px 0;
  position: fixed;
  top: 30px;
  left: 30px;
  width: 220px;
  height: auto;
  z-index: 10;
  background: #fff;
  justify-content: space-between;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 15;
  opacity: 0;
  transition: opacity $easeInSine 300ms 1000ms;
  &.show {
    opacity: 1;
  }
  .logo {
    border-top: 1px solid $colorOR;
    border-bottom: 1px solid $colorOR;
    margin:0 auto 20px;
    width: calc(100% - 40px);
    padding:20px 0;
    a {
      img {
        width: 100%;
      }
    }
  }
  .menu-trigger {
    span {
    }
  }
  .gnav {
    ul {
      padding:0 20px;
      li {
        position: relative;
        a {
          display: block;
          padding: 10px 0;
          text-align: center;
          color: $normalC;
          transition: all $easeInSine 300ms;
          &:hover{
            background: #fdf4df;
          }
          &.contact {
            background: $colorG;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .ico {
              width: 20px;
              margin-right: 10px;
              display: inline-block;
              img {
                width: 100%;
              }
            }
          }
        }
        &.has-sub{
          
          a{}
          .sub-menu{
            position: absolute;
            left: 200px;
            top: 0;
            opacity: 0;
            background: #fff;
            padding: 15px;
            transition: all $easeInSine 300ms;
            transform:translateX(-130px);
            width: 200px;
            z-index: -1;
            li{
              a{
                display: block;
                padding: 15px 0;
                border-bottom: 1px solid #fdf4df;
                i{
                  display: inline-block;
                  width: 16px;
                  vertical-align: middle;
                  img{
                    width:100%;
                    height: auto;
                  }
                }
              }
            }
          }
          &:hover{
            
            .sub-menu{
              opacity: 1;
              transform:translateX(0px);
            }
          }
        }
      }
    }
    .tel-box {
      width: calc(100% - 40px);
      margin:0 auto;
      background: $colorOR;
      padding: 10px 5px;
      color: #fff;
      text-align: center;
      .title {
        font-size: 12px;
        margin-bottom: 5px;
      }
      .tel {
        color: #fff;
        .ico {
          width: 20px;
          margin-right: 10px;
          display: inline-block;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.menu-trigger {
  position: fixed;
  right: 0;
  top: calc(50% - 30px);
  width: 60px;
  height: 60px;
  opacity: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 3;
  &.show {
    opacity: 1;
  }
  span {
    width: 40px;
    height: 1px;
    background: #fff;
    position: relative;
    display: block;
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 15px;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #fff;
      position: absolute;
      top: 30px;
    }
  }
}
body {
  background: $normalC;
  line-height: 1.8;
  background-color: #fff;
  color: $normalC;
  font-size: 14px;
  font-family: $fg;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  a {
    text-decoration: none;
    color: $mainC;
    transition: $ease 300ms;
    &:hover {
    }
  }
}
.first-wrap {
  position: relative;
  // overflow: hidden;
  margin-bottom: 50px;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  .slider-wrap {
    position: relative;
    opacity: 0;
    transition: opacity $easeInSine 1000ms;
    &.show {
      opacity: 1;
    }
    .swiper-container {
      height: 100vh;
      .swiper-wrapper {
        .swiper-slide {
          p {
            width: 100%;
            height: 100vh;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }
    .swiper-pagination {
    }
  }
  .catch-box {
    position: absolute;
    right: 60px;
    top: 60px;
    // font-family: $fm;
    z-index: 12;
    height: 100vh;
    color: $mainC;
    line-height: 1.9;
    letter-spacing: 0.03em;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    color: #fff;
    font-family: $fm;
    h1 {
      font-size: 36px;
      margin-bottom: 15px;
      font-weight: normal;
      height: 100vh;
      transition: all $easeInSine 1000ms 800ms;
      text-shadow:0px 0px 50px rgba(0,0,0,0.8);

      opacity: 0;
      span {
        // background: #fff;
        padding: 15px;
        position: relative;
        
      }
      &.show {
        opacity: 1;
      }
    }
    h2 {
      font-size: 24px;
      height: 100vh;
      margin-right:50px;
      transition: all $easeInSine 500ms 1800ms;
      opacity: 0;
      font-weight: normal;
      text-shadow:0px 0px 30px rgba(0,0,0,0.8);

      span {
        // background: #fff;
        padding: 15px;
        position: relative;
        
      }
      &.show {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}
.top-contents {
  position: relative;
  box-sizing: border-box;
  .top-common {
    padding: 70px 50px 70px 280px;
    box-sizing: border-box;
    &.bg-y{
      background: #fff7eb;
    }
    &.bg-w{
      background: #fff;
    }
    .head-line {
      color: $normalC;
      margin-bottom: 50px;
      text-align: center;
      &.loaded {
        h1 {
          opacity: 1;
          span {
            
          }
        }
        h2 {
          opacity: 1;
          span {
            
          }
        }
      }
      h1 {
        font-family: $fm;
        font-size: 36px;
        letter-spacing: 0.2em;
        // margin-bottom: 15px;
        font-weight: normal;
        line-height: 36px;
        opacity: 0;
        transition: opacity $easeInSine 500ms 300ms;

        span {
          padding: 5px 50px 5px;
          border-bottom: 1px solid $mainC;
          display: inline-block;
          position: relative;
        
        }
      }
      h2 {
        font-family: $fg;
        font-size: 12px;
        transition: opacity $easeInSine 500ms 500ms;
        opacity: 0;
        span {
          background: transparent;
          padding: 5px 20px;
          display: inline-block;
          position: relative;

         
        }
      }
      &:after {
        // content: "";
        // width: 180px;
        // height: 1px;
        // background: $mainC;
        // display: block;
        // margin-top: 15px;
      }
    }
    &.concept-wrap {
      // background: $colorY;
      .concept-inner {
        text-align: center;
        font-family: $fm;
        font-size: 18px;
        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 15px;
          color: $mainC;
        }
        
      }
    }
    .inner {
      margin: 0 auto;
      min-width: 800px;


    }
  }
}
// TOP & SUB COMMON

.parts-list{
  display: flex;
  flex-wrap: wrap;
  
  li{
    padding: 0 15px 40px;
    width: calc(100% / 3);
    a{
      padding: 20px;
      background: #fff;
      box-sizing: border-box;
      display: block;
      position: relative;
      .photo{
        img{
          max-width: 100%;
          height: auto;
        }
      }
      .detail{
        position: relative;
        .title{
          font-weight: bold;
          margin-bottom: 15px;
          font-size: 16px;
          text-align: center;
          i{
            display: inline-block;
            width: 24px;
            vertical-align: bottom;
            padding-right: 10px;
            img{
              width: 100%;
            }
          }
        }
        .detail-text{
          color: $normalC;
        }
        .arrow{
          position: relative;
          margin:10px 0 0 0;
          &:before{
            content: '';
            width: 50%;
            margin-left:50%;
            height: 1px;
            background: rgb(153, 153, 153);
            display: block;
            transition:all 300ms;
          }
          &:after{
            content: '';
            width: 10px;
            height: 1px;
            background: rgb(153, 153, 153);
            position: absolute;
            transform: rotate(45deg);
            right: 0;
            top: -4px;
            display: block;

          }
        }
      }
      &:hover{
        .detail{
          .arrow{
            &:before{
              width: 100%;
              margin-left:0%;

            }
          }
        }
      }
    }
  }
}
.news-list {
  li {
    border-bottom: 1px solid $mainC;
    a {
      display: flex;
      padding: 15px;
      justify-content: space-between;
      .date {
        background: $colorY;
        width: 80px;
        padding: 5px;
        color: #fff;
        font-size: 12px;
        text-align: center;
      }
      
      .title {
        color: $normalC;
        width: calc(100% - 90px);
      }
    }
  }
}

.contents {
  .header-box {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0;
    font-family: $fm;
    letter-spacing: 0.2em;
    // margin-bottom: 70px;
    text-align: right;    
    .title {
      font-size: 36px;
      font-weight: normal;
      margin-bottom: 15px;
      color: #fff;
      letter-spacing: 0.2em;
      text-shadow:0px 0px 5px rgba(0,0,0,0.8);

      span {
        display: inline-block;
        padding: 0px 30px;
        // background: rgba(255, 255, 255, 0.8);
        // border-bottom: 1px solid #fff;
        &:after {
          content: "";
          display: block;
          width: calc(100% + 60px);
          margin-left: -30px;
          height: 1px;
          background: #fff;
          transform: scaleX(0);
          transform-origin: right center;
          transition: transform $easeInSine 500ms 300ms;
        }
      }
    }
    .sub-title {
      font-size: 12px;
      color: #fff;
      transition: opacity $easeInSine 500ms 300ms;
      opacity: 0;
      text-shadow:0px 0px 5px rgba(0,0,0,0.8);
      padding-right: 40px;
    }
    &.loaded {
      .title {
        span {
          &:after {
            transform: scaleX(1);
          }
        }
      }
      .sub-title {
        opacity: 1;
      }
    }
  }
  .head-line {
    color: $normalC;
    margin-bottom: 50px;
    text-align: center;
    &.loaded {
      h1 {
        span {
          
        }
      }
      h2 {
        span {
          
        }
      }
    }
    h1 {
      font-family: $fm;
      font-size: 28px;
      letter-spacing: 0.2em;
      // margin-bottom: 15px;
      font-weight: normal;
      line-height: 28px;
      span {
        padding: 5px 50px 10px;
        border-bottom: 1px solid $mainC;
        display: inline-block;
        position: relative;
        
      }
    }
    h2 {
      font-family: $fg;
      font-size: 12px;

      span {
        background: transparent;
        padding: 5px 20px;
        display: inline-block;
        position: relative;
        transition: background-color $easeInSine 500ms 300ms;

      }
    }
    &:after {
      // content: "";
      // width: 180px;
      // height: 1px;
      // background: $mainC;
      // display: block;
      // margin-top: 15px;
    }
    .cat{
      background: $mainC;
      padding: 5px 10px;
      font-size: 14px;
      color: #fff;
      display: inline-block;
      position: absolute;
      right:70px;
    }
    .tag{
      display: inline-block;
      margin-left: 20px;
      a{
        display: inline-block;
        margin-right: 10px;
        &:before{
          content: '#';
        }
      }
    }
  }
  .sub-contents {
    padding: 80px 50px 80px 280px;
    &.no-title {
      padding: 0px 50px 80px;
    }
    &.bg-y {
      background: #fff6d8;
    }
    &.bg-f {
      background: #fff;
    }
    .inner {
      margin: 0 auto;
      min-width: 800px;
      .movie-wrap{
        .movie{
          margin-bottom: 50px;
          text-align: center;
          iframe {
            
          }
        }
        
        .explain{
          background: rgb(251, 255, 232);
          padding: 30px;
          font-size: 16px;
          strong{
            font-weight: bold;
            display: inline-block;
            border-bottom: 1px solid $normalC;
          }
        }
      }
      .wb-list{
        padding: 30px;
        background: #fff;
        box-sizing: border-box;
			  dt{
          padding-top: 30px;
          .title{
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 30px;
            color: $colorOR;
          }
        }
			  dd{
          padding-bottom: 30px;
          margin-bottom: 30px;
				  .top-text{
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 30px;
          }
          .img{
            text-align: center;
            margin-bottom: 30px;
            img{
              max-width: 100%;
              height: auto;
            }
          }
				  .sub-text{
            font-size: 16px;
            margin-bottom: 30px;
          }
          border-bottom: 1px solid $mainC;
        }
      }
      .concept-inner {
        text-align: center;
        font-family: $fm;
        font-size: 18px;
        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 15px;
        }
        &.philosophy{
          .title{
            text-align: center;
          }
          text-align: left;
        }
      }
      .style-list {
        li {
          margin-bottom: 50px;
          opacity: 0;
          transition: opacity $easeInSine 500ms 300ms;
          &.loaded {
            opacity: 1;
          }
          .detai {
            display: flex;
            .photo {
              width: 320px;
              position: relative;
              z-index: 1;
              img {
                width: 100%;
                height: auto;
              }
            }

            .detail-area {
              background: rgba(255, 255, 255, 0.6);
              padding: 30px 30px 30px 50px;
              width: calc(100% - 320px);
              transform: translate3d(-15px, 15px, 0);

              .title {
                font-size: 24px;
                color: $colorG;
                font-weight: bold;
                span {
                  display: inline-block;
                  border-bottom: 1px solid $colorG;
                  margin-bottom: 10px;
                }
              }
              .text {
              }
            }
            .btn {
            }
          }
        }
      }
      .category-wrap {
        margin-bottom: 50px;
        .category-list {
          text-align: center;
          li {
            text-align: center;
            padding: 15px;
            display: inline-block;
            a {
              display: inline-block;
              padding: 15px 30px;
              background: $colorB;
              color: #fff;
              &:hover {
                background: $colorY;
              }
            }
          }
        }
      }
      .company-table {
        width: 100%;
        margin-bottom: 30px;
        tr {
          border-bottom: 1px solid #eee;
          th {
            padding: 15px;
            text-align: center;
          }
          td {
            padding: 15px;
          }
        }
      }
      .map-wrap {
        iframe {
          width: 100%;
        }
      }
      .sekoujirei-list {
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
        transition: opacity $easeInSine 600ms 300ms;
        &.loaded {
          opacity: 1;
        }
        li {
          width: calc(100% / 3);
          &:nth-child(even) {
            background: #f5f5f5;
          }
          a {
            display: block;
            padding: 30px;
            background: #fff;
            transition: background-color $easeInSine 300ms;
            &:hover {
              background: #f5f5f5;
            }
              .photo {
                margin-bottom: 30px;
                text-align: center;
                img {
                  width: auto;
                  height: 200px;
                }
              }
            .detail {
              .date {
                font-size: 12px;
                margin-bottom: 5px;
              }
              .cat {
                background: $mainC;
                padding: 5px;
                font-size: 14px;
                margin-bottom: 10px;
                color: #fff;
                display: inline-block;
              }
              .title {
                font-weight: bold;
              }
            }
          }
        }
      }
      .news-list {
        li {
          border-bottom: 1px solid $mainC;
          a {
            display: flex;
            padding: 15px;
            justify-content: space-between;
            .date {
              background: $colorY;
              width: 80px;
              padding: 5px;
              color: #fff;
              font-size: 12px;
              text-align: center;
            }
            .cat{
              background: $colorG;
              width: 80px;
              padding: 5px;
              color: #fff;
              font-size: 12px;
              text-align: center;
            }
            .title {
              color: $normalC;
              width: calc(100% - 180px);
            }
          }
        }
      }
      .voice-list {
        li {
          display: flex;
          align-items: center;
          opacity: 0;
          transition: opacity $easeInSine 600ms 300ms;
          &.loaded {
            opacity: 1;
          }
          .balloon-left {
            position: relative;
            display: inline-block;
            margin: 1.5em 0 1.5em 15px;
            padding: 15px;
            min-width: 120px;
            max-width: 100%;
            background: #fff;
            border: solid 3px $colorY;
            border-radius: 4px;
            box-sizing: border-box;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: -24px;
              margin-top: -12px;
              border: 12px solid transparent;
              border-right: 12px solid #fff;
              z-index: 2;
            }

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: -30px;
              margin-top: -14px;
              border: 14px solid transparent;
              border-right: 14px solid $colorY;
              z-index: 1;
            }
          }
          .icon {
            width: 140px;
            height: 140px;
            padding: 30px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 70px;
            margin-right: 20px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          &.right {
            flex-direction: row-reverse;
            .balloon-right {
              position: relative;
              display: inline-block;
              margin: 1.5em 15px 1.5em 0;
              padding: 15px;
              min-width: 120px;
              max-width: 100%;
              background: #fff;
              border: solid 3px $colorY;
              box-sizing: border-box;
              border-radius: 4px;

              &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: -24px;
                margin-top: -12px;
                border: 12px solid transparent;
                border-left: 12px solid #fff;
                z-index: 2;
              }

              &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: -30px;
                margin-top: -14px;
                border: 14px solid transparent;
                border-left: 14px solid $colorY;
                z-index: 1;
              }
            }

            .icon {
              margin-right: 0;
              margin-left: 20px;
            }
          }
        }
      }
      .banner-list {
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
        transition: opacity $easeInSine 600ms 300ms;
        &.loaded {
          opacity: 1;
        }
        li {
          width: 50%;
          padding: 15px;
          box-sizing: border-box;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;
            position: relative;
            flex-wrap: wrap;
            &:after {
              content: "";
              position: absolute;
              left: 15px;
              top: 15px;
              border: 1px solid #fff;
              width: calc(100% - 30px);
              height: calc(100% - 30px);
            }
            &.bg-y {
              background: $colorY;
            }
            &.bg-p {
              background: #e69c99;
            }
            &.bg-b {
              background: $colorB;
            }
            .icon {
              width: 100px;
              height: 100px;
              border-radius: 50px;
              background: #fff;
              margin-right: 15px;
              overflow: hidden;
              padding: 20px;
              img {
                width: 100%;
              }
            }
            .detail {
              width: calc(100% - 115px);
              .title {
                color: #fff;
                font-weight: bold;
                font-size: 18px;
                font-family: $fm;
              }
              .text {
                color: #fff;
              }
            }
          }
        }
      }
      .entry-detail {
        .entry-header {
          // background: #fefefe;
          // padding: 30px;
          margin-bottom: 30px;
          position: relative;
          .title {
            color: $colorG;
            font-size: 24px;
            border-bottom: 1px solid $colorG;
            padding-bottom: 5px;
            margin-bottom: 2px;
          }
          .date {
            font-size: 12px;
          }
          .cat {
            font-size: 12px;
            background: $colorG;
            color: #fff;
            padding: 2px 5px;
            text-align: center;
            position: absolute;
            right: 0px;
            bottom: -1px;
          }
        }
        .entry {
          border: 1px solid $colorG;
          background: #fff;
          padding: 30px;
          p {
          }
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      .gallery-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        li{
          width: calc(100% / 3);
          padding: 0 15px 0;
          margin-bottom: 30px;
          a{
            display: block;
            transition: all 300ms;
            opacity: 1;
            text-align: center; 
            &:hover{
              opacity: 0.8;
            }
            img{
              height: 200px;
              width: auto;
              
            }
          }
        }
      }
      .sekou-main-image{
        text-align: center;
        margin-bottom: 30px;
        img{
          max-width: 100%;
        }
      }
      .comment{
        background: #fff;
        margin-bottom: 30px;
        padding: 15px;
      }
      .detail-table{
        table{
          border-top: 1px solid $normalC;
          width: 100%;
          tr{
            border-bottom: 1px solid $normalC;
            th{
              padding: 15px;

            }
            td{
              padding: 15px;
            }
          }
        }
        
      }
    }
  }
}
.footer {
  position: relative;
  padding: 50px 50px 50px 280px;
  background: #f8ffeb;
  a{
    color: $colorG;
  }
  .page-top {
    display: none;
    a {
      span {
      }
    }
  }
  .footer-inner {
    min-width: 800px;
    margin: 0 auto;
    .footer-banner{
      margin-bottom: 50px;
      ul{
        li{
          margin-bottom: 50px;
          position: relative;

          a{
            display: block;
            position: relative;
            img{
              width: 100%;
              height: auto;
            }
            .title{
              position: absolute;
              bottom: 0;
              width: 100%;
              padding:10px;
              color: #fff;
              background: rgba(0,0,0,0.4);
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .footer-menu {
      ul{
        li {
          position: relative;
          margin-bottom: 15px;
          a {
            padding: 5px 0 5px 0;
            font-size: 16px;
            vertical-align: middle;
            &.contact {
              .ico {
                display: none;
                
              }
            }
            i{
              display: inline-block;
              width: 40px;
              padding-right: 5px;
              img{
                width: 100%;
              }
            }
          }
          &.has-sub{
            .sub-menu{
              li{
                display: inline-block;
                margin-bottom: 0;
                margin-right: 10px;
                a{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      
    }
    .hospital-area{
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .address{
        width: 300px;
        .title{
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 15px;
          
        }
        .detail{

        }
      }
      .schedule{
        width: calc(100% - 300px);
        padding-left: 15px;
        table{
          width: 100%;
          border: 1px solid $colorGray;
          margin-bottom: 15px;
          tr{
            th{
              border: 1px solid $colorGray;
              padding: 10px;
              text-align: center;
              background: $mainC;
              color: #fff;
            }
            td{
              border: 1px solid $colorGray;
              padding: 10px;
              text-align: center;
              background: #fff;
            }
          }
        }
        .schedule-text{
          text-align: right;
        }
      }
    }
  }
  .copy {
    padding-top: 30px;
    text-align: center;
    p {
    }
  }
}
.sub-page {
  section {
    .inner {
      min-width: 1000px;
      max-width: 1400px;
    }
  }
}

.more {
  display: block;
  width: 300px;
  height: 60px;
  background: $mainC;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  margin: 50px auto;
  &:hover {
    background: $colorB;
    color: #fff;
  }
  &.bg-f {
    color: $mainC;
    background: #fff;
  }
}

form p {
  margin-bottom: 30px;
  border-bottom: solid #eee 1px;
  padding-bottom: 10px;
}
form p select {
  display: inline;
}
form p textarea {
  vertical-align: top;
}
.attention {
  color: #f00;
}

form .back{
  display: inline-block;
    padding: 15px 50px;
    box-sizing: border-box;
    background: #ccc;
    font-size: 16px;
}
form .confirm{
  display: inline-block;
  padding: 15px 50px;
  box-sizing: border-box;
  background: #333333;
  font-size: 16px;
  color: #fff;
  vertical-align: top;
  font-weight: bold;
}
